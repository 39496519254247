<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="row">
            <div class="col-12 col-lg-6 pe-xl-5">
              <slot></slot>
            </div>
            <div class="col-12 col-lg-6">
              <div class="card__img">
                <img :src="`/img/components/card/img-${img}-desk.png`" alt="card-img">
                <img :src="`/img/components/card/img-${img}-mob.png`" alt="card-img">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    img: {
      required: true,
      type: String
    }
  }
}
</script>
