<template>
  <div class="container">
    <div class="row mb-4 mb-md-5">
      <div class="col-12">
        <ui-title tag="2" class="ta-center mb-3 mb-md-4">
          {{ cc.title }}
        </ui-title>
        <ui-text tag="3" class="tc-gray lh-175 ta-center">
          {{ cc.text }}
        </ui-text>
      </div>
    </div>
    <div class="row">
<!--      <div v-for="(item, i) in cc.boxes" :key="`cont-item-${i}`"
           class="col-12 col-sm-6 col-md-6 col-lg-5 col-xl-4"
           :class="[
              {'offset-lg-1 offset-xl-2 mb-4 mb-sm-0 pe-lg-3': i === 0},
              {'ps-lg-3': i === 1}
           ]">
        <div class="contact">
          <div class="ico ico&#45;&#45;contact mb-3 mb-md-4"
               :class="`ico&#45;&#45;contact-${item.theme}`">
            <img :src="`/img/icons/ico-${item.key}-${item.theme}.svg`" :alt="item.key"/>
          </div>
          <ui-title tag="5" class="mb-3 mb-md-4">
            {{ item.title }}
          </ui-title>
          <ui-text tag="1" class="tc-gray lh-175 mb-3 mb-md-4">
            {{ item.text }}
          </ui-text>
          <ui-link :data="item.link" :modifier="item.link.modifier" class="ts-16 ts-md-20" />
        </div>
      </div>-->
      <div class="col-12 col-sm-6 offset-sm-3 col-md-4 offset-md-4">
        <div class="contact">
          <div class="ico ico--contact mb-3 mb-md-4"
               :class="`ico--contact-${cc.boxes[0].theme}`">
            <img :src="`/img/icons/ico-${cc.boxes[0].key}-${cc.boxes[0].theme}.svg`" :alt="cc.boxes[0].key"/>
          </div>
          <ui-title tag="5" class="mb-3 mb-md-4">
            {{ cc.boxes[0].title }}
          </ui-title>
          <ui-text tag="1" class="tc-gray lh-175 mb-3 mb-md-4">
            {{ cc.boxes[0].text }}
          </ui-text>
          <ui-link :data="cc.boxes[0].link" :modifier="cc.boxes[0].link.modifier" class="ts-16 ts-md-20" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import uiTitle from '@/components/UI/Title'
import uiText from '@/components/UI/Text'
import uiLink from '@/components/UI/Link'
export default {
  components: {
    uiTitle, uiText, uiLink
  },
  computed: {
    cc () {
      return this.$t('components.contact')
    }
  }
}
</script>
