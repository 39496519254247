<template>
  <main class="main" data-view="wallets">
    <section class="section" id="s1">
      <comp-card img="1">
        <ui-title tag="4" class="ta-center ta-lg-start mb-4">
          {{ cc.s1.title }}
        </ui-title>
        <ui-text tag="3" class="tc-gray ta-center ta-lg-start lh-35 mb-md-4">
          {{ cc.s1.text[0] }}
        </ui-text>
        <ui-text tag="3" class="tc-gray ta-center ta-lg-start lh-35">
          {{ cc.s1.text[1] }}
        </ui-text>
      </comp-card>
    </section>
    <section class="section" id="s2">
      <div class="container">
        <div class="row mb-4 mb-md-5">
          <div class="col-12">
            <ui-text tag="4" class="tc-waikawa tw-500 lh-35 ta-center">
              {{ cc.s2.pre }}
            </ui-text>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-10 offset-lg-1 col-xl-8 offset-xl-2">
            <div class="cryptel">
              <div class="cryptel__row">
                <div class="cryptel__item mb-4 mb-md-5" v-for="(item, i) in cryptoFilter" :key="`cryptel-item-${i}`">
                  <div class="cryptel__icon mb-3">
                    <img :src="`/img/icons/crypto/${item}.svg`" alt="">
                  </div>
                  <p class="text tc-waikawa tw-500 ta-center mb-2">{{ cryptoNamespace[item] }}</p>
                  <p class="text tc-gray ts-14 ta-center">{{ item.toUpperCase() }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section section--snow" id="s3">
      <comp-contact />
    </section>
  </main>
</template>
<script>
import compCard from '@/components/Card'
import compContact from '@/components/Contact'
import uiTitle from '@/components/UI/Title'
import uiText from '@/components/UI/Text'
export default {
  components: {
    compCard, compContact, uiTitle, uiText
  },
  computed: {
    cc () {
      return this.$t('pages.wallets')
    },
    cryptoNamespace () {
      return this.$store.state.cryptoNamespace
    },
    cryptoFilter () {
      const arr = []
      const blackList = ['dash', 'glm', 'xmr', 'zec', 'doge', 'czk']
      for (const item in this.cryptoNamespace) {
        if (!(blackList.includes(item))) {
          arr.push(item)
        }
      }
      return arr
    }
  },
  metaInfo () {
    const meta = this.$t('meta.wallets')
    return {
      titleTemplate: '%s' + (meta.title ? ' | ' + meta.title : ''),
      meta: [
        { name: 'keywords', content: meta.keywords },
        { name: 'description', content: meta.description },
        { property: 'og:description', content: meta.description }
      ]
    }
  },
  mounted () {
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 100)
  }
}
</script>
